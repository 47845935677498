// components/ToggleChartPage.js
import React from "react";
import { useState } from "react";
import AllChaptersCountChart from "./AllChaptersCountChart";
import TwoAllChaptersCountChart from "./TwoAllChaptersCountChart";

function ToggleChartPage() {
  const [showTwoCharts, setShowTwoCharts] = useState(false);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <button
          onClick={() => setShowTwoCharts(!showTwoCharts)}
          style={{
            padding: "10px 20px",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            outline: "none",
            transition: "background-color 0.3s ease",
          }}
          onMouseOver={(e) =>
            (e.currentTarget.style.backgroundColor = "#0056b3")
          }
          onMouseOut={(e) =>
            (e.currentTarget.style.backgroundColor = "#007bff")
          }
          onFocus={(e) => (e.currentTarget.style.boxShadow = "0 0 0 2px white")}
          onBlur={(e) => (e.currentTarget.style.boxShadow = "")}
        >
          Toggle Charts
        </button>
      </div>
      {showTwoCharts ? <TwoAllChaptersCountChart /> : <AllChaptersCountChart />}
    </div>
  );
}

export default ToggleChartPage;
