import React, { useEffect, useState } from "react";
import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client";

const UserCounter = () => {
  const [usersOnline, setUsersOnline] = useState(0);

  useEffect(() => {
    // SockJS 객체 생성
    // const socket = new SockJS("http://localhost:8080/ws");
    const socket = new SockJS("https://archipia.com/ws");
    const stompClient = new Client({
      webSocketFactory: () => socket,
      debug: (str) => console.debug(str),
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
    });

    stompClient.onConnect = (frame) => {
      console.log("Connected: ", frame);
      stompClient.subscribe(
        "/topic/usersOnline",
        (messageOutput) => {
          console.log("Received message: ", messageOutput);
          const newUsersOnline = messageOutput.body;
          setUsersOnline(newUsersOnline);
        },
        (error) => {
          // 에러 콜백 함수
          console.error("Error subscribing: ", error);
        }
      );
    };

    stompClient.activate();

    // 컴포넌트 언마운트 시 연결 종료
    return () => {
      if (stompClient !== null) {
        stompClient.deactivate();
      }
    };
  }, []);
};

export default UserCounter;
