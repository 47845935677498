import React, { useCallback, useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography"; // Typography 컴포넌트를 사용하여 제목을 추가합니다.

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p>{`Chapter: ${payload[0].payload.chapter}`}</p>
        <p>{`View Count: ${payload[0].payload.viewCount}`}</p>
        <p>{`Up Count: ${payload[0].payload.upCount}`}</p>
        <p>{`Comment Count: ${payload[0].payload.commentCount}`}</p>
      </div>
    );
  }

  return null;
};

const AllChaptersCountChart = () => {
  const [chartData, setChartData] = useState([]);
  const [zoomData, setZoomData] = useState([]);
  const [startChapter, setStartChapter] = useState("");
  const [endChapter, setEndChapter] = useState("");
  const [workTitle, setWorkTitle] = useState(""); // workTitle 상태 관리
  const [submittedWorkTitle, setSubmittedWorkTitle] = useState(""); // 제출된 작품 제목 관리
  const [chartWidth, setChartWidth] = useState(window.innerWidth * 0.6); // 초기 너비 설정
  const [totalViewCount, setTotalViewCount] = useState(0);
  const [totalUpCount, setTotalUpCount] = useState(0);
  const [totalCommentCount, setTotalCommentCount] = useState(0);
  const [showViewCount, setShowViewCount] = useState(true);
  const [showUpCount, setShowUpCount] = useState(true);
  const [showCommentCount, setShowCommentCount] = useState(true);

  const fetchData = useCallback(async (title) => {
    console.log("workTitle:", title);
    console.log("submittedWorkTitle:", submittedWorkTitle);
    try {
      const response = await fetch(
        // `http://localhost:3000/api/getAllChaptersCount/${title}`
        `https://archipia.com/api/getAllChaptersCount/${title}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("Raw Data:", data);
      const formattedData = data
        .map((item) => ({
          chapter: item.index,
          date: item.date,
          viewCount: item.viewCount,
          upCount: item.upCount,
          commentCount: item.commentCount,
        }))
        .sort((a, b) => a.chapter - b.chapter);
      console.log("Formatted Data:", formattedData);
      setChartData(formattedData);
      setZoomData(formattedData);
      setSubmittedWorkTitle(title);
    } catch (error) {
      console.error("Fetch Error:", error);
      alert("데이터를 불러오는 데 실패했습니다."); // 실패 시 바로 알림 표시
    }
  }, []);

  useEffect(() => {
    console.log("Current Zoom Data:", zoomData); // zoomData 상태 변화 로깅
  }, [zoomData]);

  useEffect(() => {
    // 창 크기 변경 이벤트 핸들러
    const handleResize = () => {
      setChartWidth(window.innerWidth * 0.6); // 창 너비에 따라 차트 너비 조정
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // 빈 배열로 인해 이 useEffect는 컴포넌트 마운트 시 한 번만 실행됩니다.

  const filterDataByChapterRange = useCallback(() => {
    // 사용자로부터 시작과 끝 챕터 번호를 받아옵니다.
    const startChapterNumber = parseInt(startChapter);
    const endChapterNumber = parseInt(endChapter);

    if (isNaN(startChapterNumber) || isNaN(endChapterNumber)) {
      alert("챕터 번호는 숫자만 입력 가능합니다.");
      return;
    }

    if (startChapterNumber > endChapterNumber) {
      alert("시작 챕터 번호는 끝 챕터 번호보다 작거나 같아야 합니다.");
      return;
    }
    // chartData 배열에서 시작 챕터 번호와 끝 챕터 번호 사이의 데이터만 필터링합니다.
    const filteredData = chartData.filter(
      (item) =>
        item.chapter >= startChapterNumber && item.chapter <= endChapterNumber
    );
    setZoomData(filteredData);

    // 필터링된 데이터의 조회수 총합 계산
    const totalViews = filteredData.reduce(
      (acc, curr) => acc + curr.viewCount,
      0
    );
    setTotalViewCount(totalViews); // 조회수 총합 상태 업데이트
    console.log("Total Views:", totalViews); // 디버깅을 위한 로그 추가

    // 필터링된 데이터의 upCount 총합 계산
    const totalUps = filteredData.reduce((acc, curr) => acc + curr.upCount, 0);
    setTotalUpCount(totalUps); // upCount 총합 상태 업데이트
    console.log("Total Views:", totalUps);

    // 필터링된 데이터의 commentCount 총합 계산
    const totalComments = filteredData.reduce(
      (acc, curr) => acc + curr.commentCount,
      0
    );
    setTotalCommentCount(totalComments); // commentCount 총합 상태 업데이트
    console.log("Total Comments:", totalComments);
  }, [chartData, startChapter, endChapter]);

  const handleWorkTitleSubmit = () => {
    if (!workTitle.trim()) {
      alert("작품 제목을 입력하세요.");
      return;
    }

    fetchData(workTitle);
  };

  const handleKeyDown = useCallback((event) => {
    if (event.key === "Enter") {
      const currentTitle = event.target.value; // 현재 입력된 제목을 직접 가져옵니다.
      if (!currentTitle.trim()) {
        alert("작품 제목을 입력하세요.");
        return;
      }
      fetchData(currentTitle); // 입력된 제목을 직접 사용하여 fetchData 호출
    }
  }, []);

  const handleKeyDownForChapter = useCallback(
    (event) => {
      if (event.key === "Enter") {
        const currentStartChapter = startChapter; // 현재 시작 챕터 번호 상태
        const currentEndChapter = endChapter; // 현재 끝 챕터 번호 상태
        filterDataByChapterRange(currentStartChapter, currentEndChapter); // 입력된 값을 직접 사용하여 함수 호출
      }
    },
    [startChapter, endChapter]
  );

  // 모바일 화면 여부 체크
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Container maxWidth="xl">
      {submittedWorkTitle && (
        <Typography variant="h4" gutterBottom align="center">
          {submittedWorkTitle}
        </Typography>
      )}
      {totalViewCount !== 0 && (
        <Typography variant="h6" gutterBottom align="center">
          View Count 총합: {totalViewCount.toLocaleString()}
        </Typography>
      )}
      {totalUpCount !== 0 && (
        <Typography variant="h6" gutterBottom align="center">
          Up Count 총합: {totalUpCount.toLocaleString()}
        </Typography>
      )}
      {totalCommentCount !== 0 && (
        <Typography variant="h6" gutterBottom align="center">
          Comment Count 총합: {totalCommentCount.toLocaleString()}
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: isMobile ? 400 : 600,
        }}
      >
        <LineChart
          width={isMobile ? window.innerWidth * 1 : chartWidth} // chartWidth 상태 변수 사용
          height={isMobile ? 400 : 600}
          data={zoomData}
          margin={{ top: 50, right: 10, left: 10, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="chapter" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          {showViewCount && (
            <Line
              type="monotone"
              dataKey="viewCount"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
          )}
          {showUpCount && (
            <Line type="monotone" dataKey="upCount" stroke="#82ca9d" />
          )}
          {showCommentCount && (
            <Line type="monotone" dataKey="commentCount" stroke="#ffc658" />
          )}
        </LineChart>
      </Box>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <Button
          variant="contained"
          color={showViewCount ? "primary" : "secondary"}
          onClick={() => setShowViewCount(!showViewCount)}
          style={{ marginRight: "10px" }}
        >
          View Count 토글
        </Button>
        <Button
          variant="contained"
          color={showUpCount ? "primary" : "secondary"}
          onClick={() => setShowUpCount(!showUpCount)}
          style={{ marginRight: "10px" }}
        >
          Up Count 토글
        </Button>
        <Button
          variant="contained"
          color={showCommentCount ? "primary" : "secondary"}
          onClick={() => setShowCommentCount(!showCommentCount)}
        >
          Comment Count 토글
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <TextField
          value={workTitle}
          onChange={(e) => setWorkTitle(e.target.value)}
          onKeyDown={handleKeyDown}
          label="작품 제목"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleWorkTitleSubmit}
          style={{ marginLeft: "10px" }}
        >
          조회
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        {/* 텍스트 필드와 버튼도 가운데 정렬 */}
        <TextField
          type="number"
          value={startChapter}
          onChange={(e) => setStartChapter(e.target.value)}
          onKeyDown={handleKeyDownForChapter}
          label="시작 챕터 번호"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          type="number"
          value={endChapter}
          onChange={(e) => setEndChapter(e.target.value)}
          onKeyDown={handleKeyDownForChapter}
          label="끝 챕터 번호"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={filterDataByChapterRange}
        >
          적용
        </Button>
      </div>
    </Container>
  );
};
export default AllChaptersCountChart;
