// src/components/TopWorksKeywordsProcessingButton.js
import React, { useState } from "react";
import axios from "axios";

function TopWorksKeywordsProcessingButton() {
  const [message, setMessage] = useState("");

  const triggerTopWorksKeywordsProcessing = async () => {
    try {
      const response = await axios.get(
        // "http://localhost:3000/trigger-top-works-keywords-processing"
        "https://archipia.com/api/trigger-top-works-keywords-processing"
      );

      setMessage(response.data);
    } catch (error) {
      console.error("API 호출 실패:", error);
      setMessage("탑 500위 작품의 키워드 빈도 처리 작업 실행에 실패했습니다.");
    }
  };

  return (
    <div>
      <button onClick={triggerTopWorksKeywordsProcessing}>
        탑 500위 작품의 키워드 빈도 처리 작업 실행
      </button>
      {message && <p>{message}</p>}
    </div>
  );
}

export default TopWorksKeywordsProcessingButton;
