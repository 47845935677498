import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import AllChaptersCountChart from "./components/AllChaptersCountChart";
import TwoAllChaptersCountChart from "./components/TwoAllChaptersCountChart";
import UserCounter from "./components/UserCounter";
import AdminPage from "./components/AdminPage";
import KeywordFrequencyChart from "./components/KeywordFrequencyChart";
import ToggleChartPage from "./components/ToggleChartPage";

function App() {
  return (
    <Router>
      <div className="App">
        <UserCounter />

        <h2 style={{ textAlign: "center" }}>View Count Analysis</h2>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          {/* Toggle Charts 버튼 대신 ToggleChartPage 링크 추가 */}
          <Link to="/toggle-chart-page">
            <button
              style={{
                padding: "10px 20px",
                backgroundColor: "#007bff",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                outline: "none",
                transition: "background-color 0.3s ease",
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor = "#0056b3")
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor = "#007bff")
              }
              onFocus={(e) =>
                (e.currentTarget.style.boxShadow = "0 0 0 2px white")
              }
              onBlur={(e) => (e.currentTarget.style.boxShadow = "")}
            >
              Go to Toggle Chart Page
            </button>
          </Link>
          <Link to="/keyword-frequency-chart">
            <button
              style={{
                padding: "10px 20px",
                backgroundColor: "#28a745",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                outline: "none",
                transition: "background-color 0.3s ease",
                marginLeft: "10px", // 버튼 간 간격 설정
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor = "#218838")
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor = "#28a745")
              }
              onFocus={(e) =>
                (e.currentTarget.style.boxShadow = "0 0 0 2px white")
              }
              onBlur={(e) => (e.currentTarget.style.boxShadow = "")}
            >
              View Keyword Rank Chart
            </button>
          </Link>
        </div>
        <Routes>
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/two-charts" element={<TwoAllChaptersCountChart />} />
          <Route
            path="/keyword-frequency-chart"
            element={<KeywordFrequencyChart />}
          />
          {/* ToggleChartPage 라우팅 추가 */}
          <Route path="/toggle-chart-page" element={<ToggleChartPage />} />
          <Route path="/" element={<AllChaptersCountChart />} />{" "}
          {/* 기본 페이지 설정 */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
