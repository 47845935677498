import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  PieChart,
  Pie,
} from "recharts";

const KeywordFrequencyChart = () => {
  const [titles, setTitles] = useState([]);
  const [tags, setTags] = useState([]);
  const [top10Titles, setTop10Titles] = useState([]);
  const [top10Tags, setTop10Tags] = useState([]);
  const [category, setCategory] = useState("");

  // 데이터 불러오기 함수
  const fetchData = async () => {
    try {
      const response = await fetch(
        // `http://localhost:3000/api/keyword-frequencies/recent/${category}`
        `https://archipia.com/api/keyword-frequencies/recent/${category}`
      );
      const jsonData = await response.json();
      console.log("jsonData:", jsonData);

      // 제목과 태그 데이터 분리
      const titlesData = jsonData.filter((item) => item.title !== undefined);
      const tagsData = jsonData.filter((item) => item.tag !== undefined);

      // 제목과 태그 데이터 분리 및 상위 10개 항목 선택
      const top10TitlesData = jsonData
        .filter((item) => item.title !== undefined)
        .sort((a, b) => b.frequency - a.frequency)
        .slice(0, 10); // 상위 10개 항목만 선택
      const top10TagsData = jsonData
        .filter((item) => item.tag !== undefined)
        .sort((a, b) => b.frequency - a.frequency)
        .slice(0, 10); // 상위 10개 항목만 선택

      setTitles(titlesData); // 제목 데이터 상태 설정
      setTags(tagsData); // 태그 데이터 상태 설정
      setTop10Titles(top10TitlesData);
      setTop10Tags(top10TagsData);
    } catch (error) {
      console.error("Error fetching keyword frequencies:", error);
    }
  };

  // 버튼 클릭 이벤트 처리
  const handleSubmit = () => {
    fetchData();
  };

  // 커스터마이즈된 Label 컴포넌트
  const CustomLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    payload,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const fillColor = payload.title ? "#8884d8" : "#82ca9d";

    return (
      <text
        x={x}
        y={y}
        fill={fillColor}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}% (${payload.title || payload.tag})`}
      </text>
    );
  };

  // TitlesTable 및 TagsTable 컴포넌트 내부의 table 스타일을 수정합니다.
  const tableStyle = {
    width: "100%",
    marginBottom: "20px",
    borderCollapse: "collapse", // 셀 간의 테두리 합치기
  };

  const thStyle = {
    backgroundColor: "#f2f2f2", // 헤더 배경색
    padding: "10px", // 헤더 패딩
  };

  const trStyle = {
    borderBottom: "1px solid #ddd", // 행 아래쪽에 선 추가
  };

  const tdStyle = {
    padding: "10px", // 데이터 셀 패딩
  };

  // TitlesTable 및 TagsTable 컴포넌트에서 위 스타일을 적용합니다.
  const TitlesTable = ({ data }) => (
    <table style={tableStyle}>
      <thead>
        <tr>
          <th style={thStyle}>순위</th>
          <th style={thStyle}>제목</th>
          <th style={thStyle}>빈도수</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index} style={trStyle}>
            <td style={tdStyle}>{index + 1}</td>
            <td style={tdStyle}>{item.title}</td>
            <td style={tdStyle}>{item.frequency}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const TagsTable = ({ data }) => (
    <table style={tableStyle}>
      <thead>
        <tr>
          <th style={thStyle}>순위</th>
          <th style={thStyle}>태그</th>
          <th style={thStyle}>빈도수</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index} style={trStyle}>
            <td style={tdStyle}>{index + 1}</td>
            <td style={tdStyle}>{item.tag}</td>
            <td style={tdStyle}>{item.frequency}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <select
        value={category}
        onChange={(e) => setCategory(e.target.value)}
        style={{ marginBottom: "20px" }}
      >
        <option value="">카테고리를 선택하세요</option>
        <option value="all_top_500">전체 500위</option>
        <option value="view_top_500">상위 500위</option>
      </select>

      <button onClick={handleSubmit} style={{ marginBottom: "20px" }}>
        Fetch Data
      </button>

      {/* 제목 차트 컨테이너 */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
          width: "90%",
        }}
      >
        <div
          style={{
            boxSizing: "border-box", // 패딩과 보더를 포함한 너비 계산
            flexBasis: "calc(50% - 50px)",
            maxWidth: "650px",
            marginBottom: "50px",
            border: "1px solid #ccc",
            borderRadius: "10px",
            margin: "0 auto", // 차트 컨테이너를 중앙에 위치시키고, 상하좌우 마진을 동일하게 설정
            textAlign: "center", // 제목을 중앙에 정렬
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // 차트를 중앙에 정렬
            justifyContent: "flex-start", // 차트를 중앙에 정렬
            overflowY: "auto", // 컨테이너 내부 스크롤 가능
            maxHeight: "600px", // 컨테이너의 최대 높이를 설정
            flexGrow: 1, // 유연한 크기 조정
          }}
        >
          {titles.length > 0 && (
            <>
              <h3>제목 빈도수</h3>
              <div style={{ marginBottom: "30px" }}>
                {" "}
                {/* BarChart 아래에 마진 추가 */}
                <BarChart width={550} height={300} data={titles}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="title" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="frequency" fill="#8884d8" />
                </BarChart>
              </div>
              <BarChart width={550} height={300} data={top10Titles}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="title" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="frequency" fill="#8884d8" />
              </BarChart>
              <PieChart width={500} height={600}>
                <Pie
                  dataKey="frequency"
                  isAnimationActive={false}
                  data={top10Titles}
                  cx="50%"
                  cy="50%"
                  outerRadius={120}
                  fill="#8884d8"
                  label={<CustomLabel />}
                />
              </PieChart>
            </>
          )}
          {titles.length > 0 && (
            <>
              {/* BarChart와 PieChart 코드 */}
              <TitlesTable data={titles} />
            </>
          )}
        </div>

        <div
          style={{
            boxSizing: "border-box", // 패딩과 보더를 포함한 너비 계산
            flexBasis: "calc(50% - 50px)",
            maxWidth: "650px",
            marginBottom: "50px",
            border: "1px solid #ccc",
            borderRadius: "10px",
            margin: "0 auto", // 차트 컨테이너를 중앙에 위치시키고, 상하좌우 마진을 동일하게 설정
            textAlign: "center", // 제목을 중앙에 정렬
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // 차트를 중앙에 정렬
            justifyContent: "flex-start", // 차트를 중앙에 정렬
            overflowY: "auto", // 컨테이너 내부 스크롤 가능
            maxHeight: "600px", // 컨테이너의 최대 높이를 설정
            flexGrow: 1, // 유연한 크기 조정
          }}
        >
          {tags.length > 0 && (
            <>
              <h3>태그 빈도수</h3>
              <div style={{ marginBottom: "30px" }}>
                <BarChart width={550} height={300} data={tags}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="tag" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="frequency" fill="#82ca9d" />
                </BarChart>
              </div>
              <BarChart width={550} height={300} data={top10Tags}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="tag" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="frequency" fill="#82ca9d" />
              </BarChart>
              <PieChart width={500} height={600}>
                <Pie
                  dataKey="frequency"
                  isAnimationActive={false}
                  data={top10Tags}
                  cx="50%"
                  cy="50%"
                  outerRadius={120}
                  fill="#82ca9d"
                  label={<CustomLabel />}
                />
              </PieChart>
            </>
          )}
          {tags.length > 0 && (
            <>
              {/* BarChart와 PieChart 코드 */}
              <TagsTable data={tags} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default KeywordFrequencyChart;
