// src/components/AdminPage.js
import React from "react";
import KeywordProcessingButton from "./KeywordProcessingButton";
import TopWorksKeywordsProcessingButton from "./TopWorksKeywordsProcessingButton";

function AdminPage() {
  return (
    <div style={{ padding: "20px" }}>
      <h2>관리자 페이지</h2>
      <KeywordProcessingButton />
      <TopWorksKeywordsProcessingButton />
      {/* 추가적인 관리자 기능 컴포넌트를 여기에 위치시킬 수 있습니다. */}
    </div>
  );
}

export default AdminPage;
