import React, { useCallback, useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography"; // Typography 컴포넌트를 사용하여 제목을 추가합니다.

const TwoAllChaptersCountChart = () => {
  const [chartData1, setChartData1] = useState([]);
  const [chartData2, setChartData2] = useState([]);
  const [zoomData1, setZoomData1] = useState([]);
  const [zoomData2, setZoomData2] = useState([]);
  const [startChapter, setStartChapter] = useState("");
  const [endChapter, setEndChapter] = useState("");
  const [workTitle1, setWorkTitle1] = useState(""); // workTitle 상태 관리
  const [workTitle2, setWorkTitle2] = useState("");
  const [submittedWorkTitle1, setSubmittedWorkTitle1] = useState(""); // 제출된 작품 제목 관리
  const [submittedWorkTitle2, setSubmittedWorkTitle2] = useState("");
  const [chartWidth, setChartWidth] = useState(window.innerWidth * 0.6); // 초기 너비 설정
  const [totalViewCount1, setTotalViewCount1] = useState(0);
  const [totalViewCount2, setTotalViewCount2] = useState(0);
  const [totalCommentCount1, setTotalCommentCount1] = useState(0);
  const [totalCommentCount2, setTotalCommentCount2] = useState(0);
  const [totalUpCount1, setTotalUpCount1] = useState(0);
  const [totalUpCount2, setTotalUpCount2] = useState(0);
  const [showViewCount1, setShowViewCount1] = useState(true);
  const [showUpCount1, setShowUpCount1] = useState(true);
  const [showCommentCount1, setShowCommentCount1] = useState(true);
  const [showViewCount2, setShowViewCount2] = useState(true);
  const [showUpCount2, setShowUpCount2] = useState(true);
  const [showCommentCount2, setShowCommentCount2] = useState(true);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length > 0) {
      // payload 배열에서 viewCountWork1과 viewCountWork2 값을 찾아옵니다.
      const viewCountWork1 =
        payload.find((item) => item.dataKey === "viewCountWork1")?.value ??
        "N/A";
      const upCountWork1 =
        payload.find((item) => item.dataKey === "upCountWork1")?.value ?? "N/A";
      const commentCountWork1 =
        payload.find((item) => item.dataKey === "commentCountWork1")?.value ??
        "N/A";
      const viewCountWork2 =
        payload.find((item) => item.dataKey === "viewCountWork2")?.value ??
        "N/A";
      const upCountWork2 =
        payload.find((item) => item.dataKey === "upCountWork2")?.value ?? "N/A";
      const commentCountWork2 =
        payload.find((item) => item.dataKey === "commentCountWork2")?.value ??
        "N/A";
      const chapter = payload[0]?.payload?.chapter ?? "N/A";

      return (
        <div className="custom-tooltip">
          <p>{`Chapter: ${chapter}`}</p>
          <p>{`${submittedWorkTitle1} View Count: ${viewCountWork1}`}</p>
          <p>{`${submittedWorkTitle1} Up Count: ${upCountWork1}`}</p>
          <p>{`${submittedWorkTitle1} Comment Count: ${commentCountWork1}`}</p>
          <p>{`${submittedWorkTitle2} View Count: ${viewCountWork2}`}</p>
          <p>{`${submittedWorkTitle2} Up Count: ${upCountWork2}`}</p>
          <p>{`${submittedWorkTitle2} Comment Count: ${commentCountWork2}`}</p>
        </div>
      );
    }

    return null;
  };

  const fetchData = useCallback(async (title, index) => {
    console.log("workTitle:", title);
    try {
      const response = await fetch(
        // `http://localhost:3000/api/getAllChaptersCount/${title}`
        `https://archipia.com/api/getAllChaptersCount/${title}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("Raw Data:", data);
      const formattedData = data
        .map((item) => ({
          chapter: item.index,
          date: item.date,
          viewCount: item.viewCount,
          upCount: item.upCount,
          commentCount: item.commentCount,
        }))
        .sort((a, b) => a.chapter - b.chapter);
      console.log("Formatted Data:", formattedData);
      if (index === 1) {
        setChartData1(formattedData);
        setSubmittedWorkTitle1(title);
        setZoomData1(formattedData);
      } else if (index === 2) {
        setChartData2(formattedData);
        setSubmittedWorkTitle2(title);
        setZoomData2(formattedData);
      }
    } catch (error) {
      console.error("Fetch Error:", error);
      alert("데이터를 불러오는 데 실패했습니다."); // 실패 시 바로 알림 표시
    }
  }, []);

  useEffect(() => {
    console.log("Current Zoom Data:", zoomData1, "", zoomData2); // zoomData 상태 변화 로깅
  }, [zoomData1, zoomData2]);

  useEffect(() => {
    // 창 크기 변경 이벤트 핸들러
    const handleResize = () => {
      setChartWidth(window.innerWidth * 0.6); // 창 너비에 따라 차트 너비 조정
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // 빈 배열로 인해 이 useEffect는 컴포넌트 마운트 시 한 번만 실행됩니다.

  const filterDataByChapterRange = () => {
    // 사용자로부터 시작과 끝 챕터 번호를 받아옵니다.
    const startChapterNumber = parseInt(startChapter);
    const endChapterNumber = parseInt(endChapter);

    if (isNaN(startChapterNumber) || isNaN(endChapterNumber)) {
      alert("챕터 번호는 숫자만 입력 가능합니다.");
      return;
    }

    if (startChapterNumber > endChapterNumber) {
      alert("시작 챕터 번호는 끝 챕터 번호보다 작거나 같아야 합니다.");
      return;
    }
    //TODO: zoomdata 처리해야 함
    //TODO: 그래프 2개면 처음 시작과 끝을 어떻게 할 것인지?
    // chartData 배열에서 시작 챕터 번호와 끝 챕터 번호 사이의 데이터만 필터링합니다.

    // chartData 배열에서 시작 챕터 번호와 끝 챕터 번호 사이의 데이터만 필터링합니다.
    let sumViewCount1 = 0;
    let sumViewCount2 = 0;
    let sumCommentCount1 = 0;
    let sumCommentCount2 = 0;
    let sumUpCount1 = 0;
    let sumUpCount2 = 0;

    const filteredData1 = chartData1.filter((item) => {
      if (
        item.chapter >= startChapterNumber &&
        item.chapter <= endChapterNumber
      ) {
        sumViewCount1 += item.viewCount; // 조회수 총합 계산
        sumCommentCount1 += item.commentCount;
        sumUpCount1 += item.upCount;

        return true;
      }
      return false;
    });
    setZoomData1(filteredData1);
    setTotalViewCount1(sumViewCount1); // 총합 상태 업데이트
    setTotalCommentCount1(sumCommentCount1);
    setTotalUpCount1(sumUpCount1);

    const filteredData2 = chartData2.filter((item) => {
      if (
        item.chapter >= startChapterNumber &&
        item.chapter <= endChapterNumber
      ) {
        sumViewCount2 += item.viewCount; // 조회수 총합 계산
        sumCommentCount2 += item.commentCount;
        sumUpCount2 += item.upCount;
        return true;
      }
      return false;
    });
    setZoomData2(filteredData2);
    setTotalViewCount2(sumViewCount2); // 총합 상태 업데이트
    setTotalCommentCount2(sumCommentCount2);
    setTotalUpCount2(sumUpCount2);
  };

  const handleWorkTitleSubmit = (index) => {
    let title = "";
    if (index === 1) {
      title = workTitle1;
    } else if (index === 2) {
      title = workTitle2;
    }
    console.log("handleWorkTitleSubmit - index: ", index);
    console.log("handleWorkTitleSubmit - workTitle1: ", workTitle1);
    console.log("handleWorkTitleSubmit - workTitle2: ", workTitle2);
    console.log("handleWorkTitleSubmit - title: ", title);

    // 제목이 입력되지 않았을 경우 안내 메시지 표시
    if (!title.trim()) {
      alert("작품 제목을 입력하세요.");
      return;
    }

    fetchData(title, index);
  };

  const handleKeyDown = useCallback((event, index) => {
    if (event.key === "Enter") {
      const currentTitle = event.target.value; // 현재 입력된 제목을 직접 가져옵니다.

      if (!currentTitle.trim()) {
        alert("작품 제목을 입력하세요.");
        return;
      }
      fetchData(currentTitle, index); // 입력된 제목을 직접 사용하여 fetchData 호출
    }
  }, []);

  const handleKeyDownForChapter = useCallback(
    (event) => {
      if (event.key === "Enter") {
        const currentStartChapter = startChapter; // 현재 시작 챕터 번호 상태
        const currentEndChapter = endChapter; // 현재 끝 챕터 번호 상태
        filterDataByChapterRange(currentStartChapter, currentEndChapter); // 입력된 값을 직접 사용하여 함수 호출
      }
    },
    [startChapter, endChapter]
  );

  const mergeDataWithEmptySpaces = () => {
    // 모든 chapter 값을 찾습니다.
    const allChapters = [
      ...new Set([
        ...zoomData1.map((item) => item.chapter),
        ...zoomData2.map((item) => item.chapter),
      ]),
    ];

    // 각 chapter에 대해 두 작품의 데이터를 병합합니다.
    const mergedData = allChapters.map((chapter) => {
      const dataFromWork1 = zoomData1.find((item) => item.chapter === chapter);
      const dataFromWork2 = zoomData2.find((item) => item.chapter === chapter);

      return {
        chapter,
        viewCountWork1: dataFromWork1 ? dataFromWork1.viewCount : 0,
        upCountWork1: dataFromWork1 ? dataFromWork1.upCount : 0, // 작품 1의 추천수 추가
        commentCountWork1: dataFromWork1 ? dataFromWork1.commentCount : 0, // 작품 1의 댓글수 추가
        viewCountWork2: dataFromWork2 ? dataFromWork2.viewCount : 0,
        upCountWork2: dataFromWork2 ? dataFromWork2.upCount : 0, // 작품 2의 추천수 추가
        commentCountWork2: dataFromWork2 ? dataFromWork2.commentCount : 0, // 작품 2의 댓글수 추가
        workTitle1: submittedWorkTitle1,
        workTitle2: submittedWorkTitle2,
      };
    });

    return mergedData;
  };

  const chartData = mergeDataWithEmptySpaces();

  // 모바일 화면 여부 체크
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Container maxWidth="xl">
      <>
        <Typography variant="h4" gutterBottom align="center">
          {submittedWorkTitle1} vs {submittedWorkTitle2}
        </Typography>
        {/* 조회수 총합을 표시할 부분 추가 */}
        {(totalViewCount1 !== 0 || totalViewCount2 !== 0) && (
          <Typography variant="h6" gutterBottom align="center">
            {`${submittedWorkTitle1}의 조회수 총합: ${totalViewCount1.toLocaleString()} | ${submittedWorkTitle2}의 조회수 총합: ${totalViewCount2.toLocaleString()}`}
          </Typography>
        )}
        {(totalUpCount1 !== 0 || totalUpCount2 !== 0) && (
          <Typography variant="h6" gutterBottom align="center">
            {`${submittedWorkTitle1}의 추천수 총합: ${totalUpCount1.toLocaleString()} | ${submittedWorkTitle2}의 추천수 총합: ${totalUpCount2.toLocaleString()}`}
          </Typography>
        )}
        {(totalCommentCount1 !== 0 || totalCommentCount2 !== 0) && (
          <Typography variant="h6" gutterBottom align="center">
            {`${submittedWorkTitle1}의 댓글수 총합: ${totalCommentCount1.toLocaleString()} | ${submittedWorkTitle2}의 댓글수 총합: ${totalCommentCount2.toLocaleString()}`}
          </Typography>
        )}
      </>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: isMobile ? 400 : 600,
        }}
      >
        <LineChart
          width={isMobile ? window.innerWidth * 1 : chartWidth} // chartWidth 상태 변수 사용
          height={isMobile ? 400 : 600}
          data={chartData}
          margin={{ top: 50, right: 10, left: 10, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="chapter" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          {/* 작품 1의 조회수, 추천수, 댓글수 */}
          {showViewCount1 && (
            <Line
              type="monotone"
              dataKey="viewCountWork1"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
              name={`${submittedWorkTitle1} View Count1`}
            />
          )}
          {showUpCount1 && (
            <Line
              type="monotone"
              dataKey="upCountWork1"
              stroke="#ffc658"
              name={`${submittedWorkTitle1} Up Count1`}
            />
          )}
          {showCommentCount1 && (
            <Line
              type="monotone"
              dataKey="commentCountWork1"
              stroke="#ff7300"
              name={`${submittedWorkTitle1} Comment Count1`}
            />
          )}

          {/* 작품 2의 조회수, 추천수, 댓글수 */}
          {showViewCount2 && (
            <Line
              type="monotone"
              dataKey="viewCountWork2"
              stroke="#82ca9d"
              activeDot={{ r: 8 }}
              name={`${submittedWorkTitle2} View Count2`}
            />
          )}
          {showUpCount2 && (
            <Line
              type="monotone"
              dataKey="upCountWork2"
              stroke="#73ca53"
              name={`${submittedWorkTitle2} Up Count2`}
            />
          )}
          {showCommentCount2 && (
            <Line
              type="monotone"
              dataKey="commentCountWork2"
              stroke="#44c9"
              name={`${submittedWorkTitle2} Comment Count2`}
            />
          )}
        </LineChart>
      </Box>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <Button
          variant="contained"
          color={showViewCount1 ? "primary" : "secondary"}
          onClick={() => setShowViewCount1(!showViewCount1)}
          style={{ marginRight: "10px" }}
        >
          View Count 토글
        </Button>
        <Button
          variant="contained"
          color={showUpCount1 ? "primary" : "secondary"}
          onClick={() => setShowUpCount1(!showUpCount1)}
          style={{ marginRight: "10px" }}
        >
          Up Count 토글
        </Button>
        <Button
          variant="contained"
          color={showCommentCount1 ? "primary" : "secondary"}
          onClick={() => setShowCommentCount1(!showCommentCount1)}
          style={{ marginRight: "10px" }}
        >
          Comment Count 토글
        </Button>
        <Button
          variant="contained"
          color={showViewCount2 ? "primary" : "secondary"}
          onClick={() => setShowViewCount2(!showViewCount2)}
          style={{ marginRight: "10px" }}
        >
          View Count 토글
        </Button>
        <Button
          variant="contained"
          color={showUpCount2 ? "primary" : "secondary"}
          onClick={() => setShowUpCount2(!showUpCount2)}
          style={{ marginRight: "10px" }}
        >
          Up Count 토글
        </Button>
        <Button
          variant="contained"
          color={showCommentCount2 ? "primary" : "secondary"}
          onClick={() => setShowCommentCount2(!showCommentCount2)}
        >
          Comment Count 토글
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <TextField
          value={workTitle1}
          onChange={(e) => setWorkTitle1(e.target.value)}
          onKeyDown={(e) => handleKeyDown(1)}
          label="작품 제목 1"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={workTitle2}
          onChange={(e) => setWorkTitle2(e.target.value)}
          onKeyDown={(e) => handleKeyDown(2)}
          label="작품 제목 2"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleWorkTitleSubmit(1); // 첫 번째 작품 제목 조회
            handleWorkTitleSubmit(2); // 두 번째 작품 제목 조회
          }}
          style={{ marginLeft: "10px" }}
        >
          조회
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        {/* 텍스트 필드와 버튼도 가운데 정렬 */}
        <TextField
          type="number"
          value={startChapter}
          onChange={(e) => setStartChapter(e.target.value)}
          onKeyDown={handleKeyDownForChapter}
          label="시작 챕터 번호"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          type="number"
          value={endChapter}
          onChange={(e) => setEndChapter(e.target.value)}
          onKeyDown={handleKeyDownForChapter}
          label="끝 챕터 번호"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={filterDataByChapterRange}
        >
          적용
        </Button>
      </div>
    </Container>
  );
};
export default TwoAllChaptersCountChart;
